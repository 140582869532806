import React from 'react';
import { render } from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import AppContainer from './components/widgets/AppContainer';
import App from './components/App';
import '../assets/fonts/fontawesome/fontawesome-all';
import '../assets/media/favicon.png';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../assets/stylesheets/style.scss';

render(
  <Router>
    <AppContainer>
      <App />
    </AppContainer>
  </Router>, document.getElementById('app')
);
