import React from 'react';
import { withRouter } from 'react-router-dom';
import Header from '../Header';
import Sidebar from '../Sidebar';
import Footer from '../Footer';

class AppContainer extends React.Component {
  static defaultProps = {
    fixed: false,
    showSidebar: false,
  }

  state = {
    fixed: this.props.fixed,
    showSidebar: this.props.showSidebar,
  }

  componentDidMount() {
    this.handleScroll();
    window.addEventListener('scroll', this.handleScroll)
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.handleScroll();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  toggleSidebar = (val) => {
    if (val === true) {
      document.body.className = '--sidebar-active';
    } else {
      document.body.className = '';
    }
    setTimeout(() => this.setState({ showSidebar: val }));
  }

  handleScroll = () => {
    const { fixed } = this.state;
    const windowHeight = window.innerHeight;
    const scrollTop = window.scrollY;
    const scrollHeight = document.body.scrollHeight;
    const heightThreshold = 140;
    if ((scrollTop >= heightThreshold) && (fixed === false)) {
      this.setState({ fixed: true });
    } else if ((scrollTop < heightThreshold) && (fixed !== false)) {
      this.setState({ fixed: false });
    }
  }

  render() {
    const { children } = this.props;
    const { fixed, showSidebar } = this.state;
    return (
      <div className="app-container">
        <Header toggleSidebar={this.toggleSidebar} />
        <Header small fixed={fixed} toggleSidebar={this.toggleSidebar} />
        <div className="content">
          { children }
        </div>
        <div className={`sidebar-backdrop ${showSidebar ? 'active' : ''}`} onClick={() => this.toggleSidebar(false)} />
        <Sidebar showSidebar={showSidebar} toggleSidebar={this.toggleSidebar} />
        <Footer />
      </div>
    );
  }
}

export default AppContainer;
