import React from 'react';
import { Link } from 'react-router-dom';
import Logo from '../../widgets/logo';

const ROUTES = [
  { path: '/', label: 'home' },
  { path: '/about', label: 'about' },
  { path: '/services', label: 'services' },
  { path: '/equipment', label: 'equipment' },
  { path: '/contact', label: 'contact' },
];

export default function Sidebar ({ showSidebar, toggleSidebar }) {
  return (
    <div className={`sidebar d-flex flex-column ${showSidebar ? 'active' : ''}`}>
      <div className="sidebar-header">
        <Logo />
      </div>
      <div className="sidebar-content">
        {
          ROUTES.map(route => (
            <Link className="sidebar-item" to={route.path} key={route.label} onClick={() => toggleSidebar(false)}>
              { route.label }
            </Link>
          ))
        }
      </div>
      <div className="sidebar-footer justify-content-center align-items-center d-flex">
        <div className="align-items-center d-flex">
          Join us:
          <a to="https://www.facebook.com/Teamlifting-184450028417866/">
            <i className="fab fa-facebook" />
          </a>
          <a to="https://www.instagram.com/teamlifting_ksa/?hl=en">
            <i className="fab fa-instagram" />
          </a>
          <a to="https://www.linkedin.com/company/14017730/admin/">
            <i className="fab fa-linkedin" />
          </a>
        </div>
      </div>
    </div>
  );
}
