import React from 'react';
import { Switch, Route } from 'react-router-dom';
import asyncComponent from './HOC/asyncComponent';

const Landing = asyncComponent(() =>
  import('./pages/Landing').then(module => module.default)
);

const About = asyncComponent(() =>
  import('./pages/About').then(module => module.default)
);

const Services = asyncComponent(() =>
  import('./pages/Services').then(module => module.default)
);

const Equipment = asyncComponent(() =>
  import('./pages/Equipment').then(module => module.default)
);

const Contact = asyncComponent(() =>
  import('./pages/Contact').then(module => module.default)
);

const routes = [
  { path: '/about', component: About },
  { path: '/services', component: Services },
  { path: '/equipment', component: Equipment },
  { path: '/Contact', component: Contact },
  { path: '/', component: Landing },
];

export default () => (
  <div className="page-wrap">
    <Switch>
      {
        routes.map(route => <Route key={route.path} { ...route } />)
      }
    </Switch>
  </div>
);
