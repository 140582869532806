import React from 'react';
import { Link } from 'react-router-dom';
import Logo from '../logo';

const ROUTES = [
  { path: '/', label: 'home', icon: 'fas fa-home' },
  { path: '/about', label: 'about', icon: 'fas fa-user' },
  { path: '/services', label: 'services', icon: 'fas fa-cogs' },
  { path: '/equipment', label: 'equipment', icon: 'fas fa-cog' },
  { path: '/contact', label: 'contact', icon: 'fas fa-phone' },
];

const Header = ({ fixed, small, toggleSidebar }) => {
  if (small) {
    return (
      <div className={`header ${fixed ? 'fixed' : ''} small`}>
        <div className="header-content-wrap container d-flex justify-content-between">
          <Link to="/">
            <div className="brand d-flex align-items-center">
              <Logo />
            </div>
          </Link>
          <div className="links">
            {
              ROUTES.map(route => (
                <Link to={route.path} key={route.label}>
                  { route.label }
                </Link>
              ))
            }
          </div>
          <button className="btn-sidebar-toggle" onClick={() => toggleSidebar(true)}><i className="fas fa-bars" aria-hidden="true" /></button>
        </div>
      </div>
    )
  }
  return (
    <div className="header">
      <div className="header-top">
        <div className="container d-flex justify-content-between">
          <Link to="/">
            <div className="brand d-flex align-items-center">
              <Logo />
            </div>
          </Link>
          <div className="d-flex right">
            <div className="call">
              <div className="label">CALL US</div>
              <div className="text">+966 (0)13 898 8255</div>
            </div>
            <div className="email">
              <div className="label">EMAIL US</div>
              <div className="text">info@teamlifting.com</div>
            </div>
            <div className="social">
              <a href="https://www.facebook.com/Teamlifting-184450028417866/">
                <i className="fab fa-facebook-f" />
              </a>
              <a href="https://www.instagram.com/teamlifting_ksa/?hl=en">
                <i className="fab fa-instagram" />
              </a>
              <a href="https://www.linkedin.com/company/14017730/admin/">
                <i className="fab fa-linkedin" />
              </a>
            </div>
          </div>
          <button className="btn-sidebar-toggle" onClick={() => toggleSidebar(true)}><i className="fas fa-bars" aria-hidden="true" /></button>
        </div>
      </div>
      <div className="header-bottom">
        <div className="container">
          <div className="links">
            {
              ROUTES.map(route => (
                <Link to={route.path} key={route.label}>
                  { route.label }
                </Link>
              ))
            }
          </div>
        </div>
      </div>
    </div>
  );
}

export default Header;
