import React from 'react';

const Loader = () => {
  return (
    <div className="loader-wrap text-center">
      <div
        className="loader-in inline-block d-flex align-items-center"
      >
        loading...
        <div className="ldr-rings">
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  );
}

export default Loader;
