import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Container } from 'reactstrap';
import Logo from '../logo';
import Button from 'reactstrap/lib/Button';

const ROUTES = [
  { path: '/', label: 'home', icon: 'fas fa-home' },
  { path: '/about', label: 'about us', icon: 'fas fa-user' },
  { path: '/services', label: 'our services', icon: 'fas fa-cogs' },
  { path: '/equipment', label: 'our equipments', icon: 'fas fa-cog' },
  { path: '/contact', label: 'contact us', icon: 'fas fa-phone' },
];

const services = [
  'Heavy Lifting',
  'Heavy Haulage & Special Transports',
  'Project Cargo Movement',
  'Jacking and Sliding',
  'Engineering Solutions',
  'Specialized Industrial Solutions',
];

export default () => (
  <div className="footer">
    <Container>
      <div className="footer-content">
        <Row>
          <Col className="footer-content-section bg-world" xs="12" md="6" lg="4">
            <div className="logo">
              <Link to="/">
                <div className="brand d-flex align-items-center">
                  <Logo white />
                </div>
              </Link>
            </div>
            <p>Macro level engineering approach to the heavy lift problems to achieve customer satisfaction.</p>
            <div className="social-links">
              <a className="btn btn-primary" href="https://www.facebook.com/Teamlifting-184450028417866/">
                <i className="fab fa-facebook-f" aria-hidden="true" />
              </a>
              <a className="btn btn-primary" href="https://www.instagram.com/teamlifting_ksa/?hl=en">
                <i className="fab fa-instagram" aria-hidden="true" />
              </a>
              <a className="btn btn-primary" href="https://www.linkedin.com/company/14017730/admin/">
                <i className="fab fa-linkedin" aria-hidden="true" />
              </a>
            </div>
          </Col>
          <Col className="footer-content-section pages" xs="12" md="6" lg="2">
            <div className="head">
              <h4>Quick Links</h4>
            </div>
            {ROUTES.map(route => (
              <div key={route.label} className="link-wrap">
                <Link to={route.path}>{ route.label }</Link>
              </div>
            ))}
          </Col>
          <Col className="footer-content-section" xs="12" md="6" lg="3">
            <div className="head">
              <h4>Services</h4>
            </div>
            {services.map((service, idx) => (
              <div key={service} className="link-wrap">
                <Link to={`/services?key=${idx + 1}`}>{ service }</Link>
              </div>
            ))}
          </Col>
          <Col className="footer-content-section" xs="12" md="6" lg="3">
            <div className="head">
              <h4>Contact Us</h4>
            </div>
            <div className="address">
              <div className="text">
                <div>306, Al Khuzaim Commercial Complex,</div>
                <div>King Abdul Aziz Road, Al khobar</div>
              </div>
            </div>
            <div className="basic">
              <div className="text">
                <div>Office: +966 (0)13 898 8255</div>
                <div>GM: +966 501 564 941</div>
              </div>
            </div>
            <div className="basic">
              <div className="text">
                <div>info@teamlifting.com</div>
                <div>teamlifting@gmail.com</div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </Container>
    <div className="footer-bottom">
      <Container className="d-flex justify-content-center align-items-center">
        <p>© 2018 Team Lifting</p>
      </Container>
    </div>
  </div>
);
