import React from 'react';

export default ({ blk, white }) => (
  <div className="logo">
    <div className="logo-inner d-flex justify-content-center align-items-center">
      <img className="icon" src="/media/favicon.png" alt="" />
      {blk &&
        <img className="lettering" src="/media/team_logo_blk.png" alt="" />
      }
      {white &&
        <img className="lettering" src="/media/team_logo_white.png" alt="" />
      }
      {!(white || blk) &&
        <img className="lettering" src="/media/team_logo.png" alt="" />
      }
    </div>
  </div>
);
